<template>
  <div class="profile-data-wrapper financial-graphs earnings" v-show="!loading">
    <div class="profile-options">
      <div class="row">
        <div class="col">
          <router-link class="btn primary-btn" :to="{ name: 'FinancialGraphs' }"
            ><font-awesome-icon :icon="['fas', 'arrow-left']"
          /></router-link>
        </div>
        <div class="col flex-grow-1 d-flex justify-content-end">
          <Select2
            id="filter-period"
            :options="filters.period.options"
            v-model="filters.period.value"
            class="select2-box"
            :resetOptions="false"
            @select="getCashFlow()"
          />
        </div>
        <!-- <div class="col">
          <Select2
            id="filter-connect-types"
            v-model="filters.client.value"
            :options="filters.client.options"
            class="select2-box"
            :resetOptions="false"
            :placeholder="`Connect Type`"
            @select="getConnects()"
          />
        </div> -->
      </div>
    </div>
    <div class="row grid-1">
      <div class="col">
        <div class="connects-chart">
          <h2 class="section-heading text-transform-uppercase">
            CashFlow Graph ({{ filters.period.value }})
          </h2>
          <template v-if="!inflowData">
            <div
              class="
                no-data-wrap
                d-flex
                align-items-center
                justify-content-center
              "
            >
              <img src="@/assets/images/no-graph.png" alt="no-graph" />
              <div class="desc">No data<br />to show</div>
            </div>
          </template>
          <template v-else>
            <chart-js
              :type="`bar`"
              :datasets="connectChart.datasets"
              :labels="connectChart.labels"
              :options="connectChart.options"
            />
          </template>
        </div>
      </div>
    </div>
    <div class="row grid-2">
      <div class="col">
        <div class="custom-table no-arrows">
          <table id="datatable">
            <thead>
              <tr>
                <th>Inflow</th>
                <th>Amount($)</th>
              </tr>
            </thead>
            <tbody>
              <tr v-bind="inflowData">
                <td>Receivable Earning</td>
                <td>{{ Math.round(inflowData.receivable_earning) }}</td>
              </tr>
              <tr v-bind="inflowData">
                <td>Receivable Bounes</td>
                <td>{{ Math.round(inflowData.receivable_bonus) }}</td>
              </tr>
              <tr v-bind="inflowData">
                <td>Total</td>
                <td>{{ Math.round(inflowData.earning_total) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col">
        <div class="custom-table no-arrows">
          <table id="datatable">
            <thead>
              <tr>
                <th>Outflow</th>
                <th>Amount($)</th>
              </tr>
            </thead>
            <tbody>
              <tr v-bind="outflowData">
                <td>Service Fee</td>
                <td>{{ Math.round(outflowData.service_fee) }}</td>
              </tr>
              <tr v-bind="outflowData">
                <td>WHT</td>
                <td>{{ Math.round(outflowData.Wht) }}</td>
              </tr>
              <tr v-bind="outflowData">
                <td>Membership Fee</td>
                <td>{{ Math.round(outflowData.membership_fee) }}</td>
              </tr>
              <tr v-bind="outflowData">
                <td>Connects Purchased</td>
                <td>{{ Math.round(outflowData.connects) }}</td>
              </tr>
              <tr v-bind="outflowData">
                <td>Refunds</td>
                <td>{{ Math.round(outflowData.refund) }}</td>
              </tr>
              <tr v-bind="outflowData">
                <td>Withdrawl Charges</td>
                <td>{{ Math.round(outflowData.withdrawl_charges) }}</td>
              </tr>
              <tr v-bind="outflowData">
                <td>Total</td>
                <td>{{ Math.round(outflowData.outflow_total) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="profile-footer d-flex justify-content-end">
      <button class="primary-btn" type="button" @click="downloadCsv()">
        <font-awesome-icon :icon="['fa', 'download']" /> Download
      </button>
    </div>
  </div>
</template>
<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronRight,
  faDownload,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
library.add(faChevronRight, faDownload, faArrowLeft);

import FinancialCashFlowService from "../services/financialCashFlow.service.js";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.html5.js";
//import $ from "jquery";
import Select2 from "../components/Select2.vue";
import '@vuepic/vue-datepicker/dist/main.css'
import ChartJs from "../components/ChartJs.vue";
import dataLabels from "chartjs-plugin-datalabels";
//import financialGraphService from "../services/financialGraph.service";
import common from "../mixins/common";

export default {
  components: { Select2, FontAwesomeIcon, ChartJs },
  mixins: [common],
  data() {
    return {
      loading: false,
      filters: {
        period: {
          options: ["Monthly", "Annually"],
          value: "Monthly",
        },
        client: {
          options: [],
          value: "",
        },
      },

      inflowData: {},
      outflowData: {},
      connectChart: {
        labels: [],
        datasets: [
          {
            label: "Inflow",
            data: [],
            tension: 0,
            barBorderColor: "#6fda45",
            barBackgroundColor: "#6fda45",
            type: "bar",
            barThickness: 50,
            barPercentage: 50,
            order: 1,
          },
          {
            label: "Outflow",
            data: [],
            tension: 0,
            barBorderColor: "#FF7F50",
            barBackgroundColor: "#FF7F50",
            type: "bar",
            barThickness: 50,
            barPercentage: 50,
            order: 0,
          },
        ],
        plugins: [dataLabels],
        options: {
          responsive: true,
          maintainAspectRatio: false,
          hover: { mode: null },
          layout: {
            padding: {
              top: 10,
              bottom: 10,
            },
          },
          plugins: {
            legend: {
              position: "top",
            },
            title: {
              display: true,
              text: "Inflow/Outflow Chart",
            },
            datalabels: {
              backgroundColor: "transparent",
              borderRadius: 4,
              color: "#fff",
              font: {
                weight: "800px",
                size: '16px "Montserrat", sans-serif',
              },
              padding: 0,
              formatter: function (value) {
                return `${Math.round(value)}`;
              },
            },
          },
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
            },
          },
        },
      },
    };
  },
  async mounted() {
    await this.getCashFlow();
    this.loading = false;
  },
  methods: {
    async getCashFlow() {
      let vm = this;
      try {
        vm.$store.commit("loadingStatus", true);
        const response = await FinancialCashFlowService.get(
          vm.filters.period.value
        );
        vm.inflowData = response.inflow;
        vm.outflowData = response.outflow;
        /* Prepare data form bar chart */
        vm.connectChart.datasets[0].data = response.graph_data_inflow
          .slice()
          .reverse();
        vm.connectChart.datasets[1].data = response.graph_data_outflow
          .slice()
          .reverse();
        vm.connectChart.labels = response.graph_data_lbs.slice().reverse();

        vm.$store.commit("loadingStatus", false);
      } catch (error) {
        const message = vm.errorMessage(error);
        vm.toastMessage(message, "error");
        vm.$store.commit("loadingStatus", false);
      }
    },
    async downloadCsv(){

        let response = await FinancialCashFlowService.get( "Monthly" );
        

        let inflow = response.inflow;
        let outflow = response.outflow;

        let csv = '"Inflow","Amount($)","","","","Outflow","Amount($)"\n';
        
        csv += '"Receivable Earning",';
        csv += '"'+Math.round(inflow.receivable_earning)+'",';
        csv += '"",',
        csv += '"",',
        csv += '"",',
        csv += '"Service Fee",';
        csv += '"'+Math.round(outflow.service_fee)+'",';
        csv += "\n"; 

        csv += '"Receivable Bounes",';
        csv += '"'+Math.round(inflow.receivable_bonus)+'",';
        csv += '"",',
        csv += '"",',
        csv += '"",',
        csv += '"WHT",';
        csv += '"'+Math.round(outflow.Wht)+'",';
        csv += "\n"; 

        csv += '"Total",';
        csv += '"'+Math.round(inflow.earning_total)+'",';
        csv += '"",',
        csv += '"",',
        csv += '"",',
        csv += '"Membership Fee",';
        csv += '"'+Math.round(outflow.membership_fee)+'",';
        csv += "\n"; 

        csv += '"",';
        csv += '"",';
        csv += '"",',
        csv += '"",',
        csv += '"",',
        csv += '"Connects Purchased",';
        csv += '"'+Math.round(outflow.connects)+'",';
        csv += "\n"; 

        csv += '"",';
        csv += '"",';
        csv += '"",',
        csv += '"",',
        csv += '"",',
        csv += '"Refunds",';
        csv += '"'+Math.round(outflow.refund)+'",';
        csv += "\n"; 

        csv += '"",';
        csv += '"",';
        csv += '"",',
        csv += '"",',
        csv += '"",',
        csv += '"Withdrawl Charges",';
        csv += '"'+Math.round(outflow.withdrawl_charges)+'",';
        csv += "\n"; 

        csv += '"",';
        csv += '"",';
        csv += '"",',
        csv += '"",',
        csv += '"",',
        csv += '"Total",';
        csv += '"'+Math.round(outflow.outflow_total)+'",';
        csv += "\n"; 

        this.processCsvDownload(csv,'Cash Flow.csv');
    },
    processCsvDownload(csv,name){
      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      anchor.download = name;
      anchor.click();
    }
  },
};
</script>
